import React, {useEffect} from 'react';
// import { Counter } from './features/counter/Counter';
import './App.css';
// import BackgroundParticles from './BackgroundParticles';
import { motion, AnimatePresence } from "framer-motion"
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";



// Pages
import GreetingPage from './pages/GreetingPage';
import MainPage from './pages/MainPage';
// import ResearchPage from './pages/ResearchPage';
import ResumePage from './pages/ResumePage';
import BioPage from './pages/BioPage';
// import ProjectsPage from './pages/ProjectsPage';

import {updateNightMode} from "./components/data"




function App() {
  const [isFirstMount, setIsFirstMount] = React.useState(true);
  const location = useLocation();

  useEffect(()=>{
    updateNightMode();
   }, [])

  return (
    <div className="App">
      <AnimatePresence mode='wait'>
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            exact
            Component={(props) => (
              <GreetingPage isFirstMount={isFirstMount} {...props} />
            )}
          />
          <Route path="/MainPage" Component={MainPage} />
          {/* <Route path='/Research' Component={ResearchPage} /> */}
          <Route path='/Resume' Component={ResumePage}/>
          <Route path='/Bio' Component={BioPage} />
          {/* <Route path='/Projects' Component={ProjectsPage} /> */}
        </Routes>
      </AnimatePresence>
          
    </div>
  );
}

export default App;
