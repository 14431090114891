import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import './IntroCard.css';
import { shadows } from '@mui/system';

function IntroCard() {
  return (
    <Card className='intro-card' sx={{boxShadow:"0 19px 38px rgba(0,0,0,0.40), 0 15px 12px rgba(0,0,0,0.32)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    '&:hover': {scale: "1.1", boxShadow: "0 21px 42px rgba(0,0,0,0.40), 0 17px 20px rgba(0,0,0,0.32)"} }}>
        <CardContent>
            <CardMedia 
            component="img"
            sx={
                {objectFit:"contain", 
                boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)", 
                transition: "all 0.3s cubic-bezier(.25,.8,.25,1)", 
                '&:hover': {boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)"}}}
            image={require("./../../Images/portrait1.jpg")}
            style={{width:'150px', float:'left', marginRight:'15px'}}
            />
            <div className='textContainer'>
                
            <Typography variant='h5'>
                Thanks for stopping by...
            </Typography>
            <Typography style={{marginTop:'8px'}}>
                I started making this website halfway through July of 2023, and this is it's second iteration. 
                This iteration was made using a lot of React, some Bootstrap, some Redux, and was a whole lot of fun to make.
                <br/><br/>
                This website serves as my portfolio, but I also have plans to add some games that you can play. 
                I'm working on a Pet Park where a bunch of user submitted animals can run around and interact with eachother. 
                <br/><br/>
                Be sure to keep scrolling to see my research/projects and some more information about me. 
                If you find any problems with the website please let me know by using the contact located at the bottom left of this page.

                <br/><br/>
                Thanks again and have fun interacting with my website!
                
            </Typography>
            
            </div>
        </CardContent>
    </Card>
  )
}

export default IntroCard
