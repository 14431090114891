export const AuggieImg = require('./Augie.jpg');
export const WebsiteImg = require('./Website.png');
export const NetflixImg = require('./NetflixLogo.jpg');
export const PongImg = require('./Pong.jpg');
export const SVMImg = require('./SVM.png');
export const SnakeImg = require('./snake.jpg');
export const WeatherImg = require('./weather.png');
export const AerochainImg = require('./aeroChain.png');
export const TenImg = require('./10x.png');
export const SecImg = require('./Security.png');
export const resume = require('./Josh_Resume_For_Website.pdf');