import React from "react";
import "./ResearchSection.css";
import Container from "react-bootstrap/Container";
// import SectionHeader from "../SectionHeader";
import ResearchCard from "./ResearchCard";
import Row from "react-bootstrap/Row";
import { research } from "../data.js";
import { Grid } from '@mui/material';

function ResearchSection() {
  return (
    <section id="research">
      <h1 className="research-header">Research</h1>
      <Grid container spacing={5} 
      // direction="column"
      alignItems="center"
      justifyContent="center">
        {research.map((res, index) => (
          <ResearchCard res={res} />
        ))}
      </Grid>
    </section>
  );
}

export default ResearchSection;
