import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import './GridCard.css';
import { shadows } from '@mui/system';
import { CardHeader } from '@mui/material';

function GoalsCard() {
  return (
    <Card className='grid-card' sx={{boxShadow:"0 15px 30px rgba(0,0,0,0.32), 0 10px 10px rgba(0,0,0,0.37)", 
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    '&:hover': {scale: "1.1", boxShadow: "0 23px 41px rgba(0,0,0,0.40), 0 17px 14px rgba(0,0,0,0.40)"} }}>
        <CardContent>
            <Typography variant='h3' style={{marginBottom:'10px'}}>
                Goals
            </Typography>
            <Typography variant='h5'>
                <ul>
                    <li style={{marginBottom:'8px'}}>Get a software developer job :)</li>
                    <li style={{marginBottom:'8px'}}>Get Chess working on here
                        <ul>
                            <li>Allow for muliplayer matches</li>
                            <li>Create database of player leaderboard</li>
                            <li>Somehow incorperate SQL to practice it</li>
                        </ul>
                    </li>
                    {/* <br></br> */}
                    <li>Get the Pet Park up and running</li>
                </ul>
            </Typography>
        </CardContent>
    </Card>
  )
}

export default GoalsCard
