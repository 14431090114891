import React, { useState } from 'react';
import './ProjectCard.css';
// import Badge from 'react-bootstrap/Badge'
import { CardHeader, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import { NavLink } from 'react-router-dom';
import Badge from '@mui/material/Badge';



function ProjectLanguageTags({ languages }) {
    return (
        <div className="project-tags">
            {languages.map((lang, index) =>
                <Badge className="proj-tag" type='info'>{lang}</Badge>
            )}
        </div>
    );
}

function ProjectCard({ proj }) {
    return (
        <Grid item >
        <Card className='proj-card'
        sx={{ borderRadius: 5 }}>
            <CardHeader
            title={proj.title}
            className='proj-title'
            />
            <CardActionArea component={NavLink} to={proj.link} className='card-action-area'>
                <CardMedia
                component="img"
                className='project-image'
                image={proj.img}>
                
                </CardMedia>
            </CardActionArea>
            <div className="project-description">
            <ul>
                {proj.description.map((line, index) =>
                        <li className="project-description-text">
                            {line}
                        </li>
                )}
                <li>
                        <ProjectLanguageTags languages={proj.languages} />
                </li>
            </ul>
            </div>
        </Card>
        </Grid>

    );
}







export default ProjectCard;