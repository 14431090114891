import React from 'react'
// import { Counter } from './features/counter/Counter';
// import './App.css';
import './InitialScreen.css';
// import BackgroundParticles from './BackgroundParticles';
import InitialScreen from './InitialScreen';
import { motion} from "framer-motion"
import { NavLink } from "react-router-dom";

function GreetingPage() {
  const [show, setShow] = React.useState(true);

  return (
    <div>
        {show && (
          <motion.div 
            animate={{opacity: 1}}
            exit={{opacity:0}}
            transition={{ duration: 2}}>
          <div className="Initial__Screen">
            <InitialScreen />
            <div className="welcomeMessage">
                
            <p className='welcome'>Welcome to Josh Pike's Website</p>
            <div className='navDiv'>
            <motion.div
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
                <NavLink
                className="nav_link"
                    
                    exact
                    style={({ isActive }) =>
                        isActive
                        ? {
                        color: '#fff',
                        background: '#7600dc',
                        textDecoration: 'none'
                        }
                        : { fontSize:'24pt', color: '#545e6f', background: '#f0f0f0', textDecoration: 'none' }
                    }
                to="/MainPage"
                >
                Enter Site
                </NavLink>
            </motion.div>
            </div>
                {/* <NavLink
                className="nav_link"
                activeClassName = "nav_link_active"
                
                    exact
                to="/MainPage"
                >
                Enter Site
                </NavLink> */}
            </div>
          </div>
          
          </motion.div>
        )}

      
    </div>
  )
}

export default GreetingPage
