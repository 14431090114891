import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import './GridCard.css';
import { shadows } from '@mui/system';
import { CardHeader } from '@mui/material';

function FreeTimeCard() {
  return (
    <Card className='grid-card' sx={{boxShadow:"0 15px 30px rgba(0,0,0,0.32), 0 10px 10px rgba(0,0,0,0.37)", 
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    '&:hover': {scale: "1.1", boxShadow: "0 23px 41px rgba(0,0,0,0.40), 0 17px 14px rgba(0,0,0,0.40)"} }}>
        <CardContent>
            <Typography variant='h3' style={{marginBottom:'10px'}}>
                Free Time
            </Typography>
            <Typography variant='h5'>
                <ul>
                    <li>Snowboarding in the winter</li>
                    <br></br>
                    <li>Video games (Rocket League is my grind game right now)</li>
                    <br></br>
                    <li>Hiking</li>
                    <br></br>
                    <li>Chess (I'm only around 800 elo though)</li>
                </ul>
            </Typography>
        </CardContent>
    </Card>
  )
}

export default FreeTimeCard
