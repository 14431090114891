import React from 'react';

// Layout
import Layout from "../components/Layout/Layout.js";
// import IntroCard from '../components/Cards/IntroCard.js'
import "./ResumePage.css";
import {resume} from "../Images";

function ResumePage() {
  return (
    <Layout>
        <div class="pdfDisplay">
                <object data={resume} type="application/pdf" width="100%" height="100%" >
                  <p><small> If the pdf doesn't appear, you probably don't have a PDF plugin for this browser. No biggie... you can <a href="Josh_Resume_For_Website.pdf">click here to download the PDF file.</a></small></p>
                </object>
            </div>
    </Layout>
  )
}

export default ResumePage;
