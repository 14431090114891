import React from 'react'
import './Navigation.css'
import { NavLink } from 'react-router-dom'

//https://codesandbox.io/s/gifted-orla-hjpx0v?from-embed=&file=/src/components/Navigation.js:71-88

function Navigation() {

  return (
    <div className='mainDiv'>
        <nav className='navbar navbar-expand-lg bg-light'>
            <div className='container-fluid'>
                {/* <a className='navbar-brand' href='/MainPage'>
                    JoshuaPike.com
                </a> */}
                <NavLink
                            to="/MainPage"
                            exact
                            className='navbar-brand'
                            >
                                JoshuaPike.com
                            </NavLink>
                <button
                    className='navbar-toggler'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#navbarSupportedContent'
                    aria-controls='navbarSupportedContent'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                >
                    <span className='navbar-toggler-icon' />
                </button>
                <div className='collapse navbar-collapse' id='navbarSupportedContent'>
                    <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
                        {/* <li className='nav-item'>
                            <NavLink
                            to="/Bio"
                            exact
                            className={({isActive}) => 
                            (isActive ? 'nav-link active' : 'nav-link')}
                            >
                                Biography
                            </NavLink>
                        </li> */}
                        <li className='nav-item'>
                            <NavLink
                            to="/Resume"
                            exact
                            className={({isActive}) => 
                            (isActive ? 'nav-link active' : 'nav-link')}
                            >
                                Resume
                            </NavLink>
                        </li>
                        {/* <li className='nav-item'>
                            <NavLink
                            to="/Research"
                            exact
                            className={({isActive}) => 
                            (isActive ? 'nav-link active' : 'nav-link')}
                            >
                                Research
                            </NavLink>
                        </li> */}
                        {/* <li className='nav-item'>
                            <NavLink
                            to="/Projects"
                            exact
                            className={({isActive}) => 
                            (isActive ? 'nav-link active' : 'nav-link')}
                            >
                                Projects
                            </NavLink>
                        </li> */}
                        {/* <li className='nav-item dropdown'>
                            <a
                            className='nav-link dropdown-toggle'
                            href='/'
                            id='navbarDropdown'
                            role='button'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                            >
                                Dropdown
                            </a>
                            <ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
                                <li>
                                    <a className='dropdown-item' href='/'>
                                        Action
                                    </a>
                                </li>
                                <li>
                                    <a className='dropdown-item' href='/'>
                                        Action2
                                    </a>
                                </li>
                                <li>
                                    <hr className='dropdown-divider' />
                                </li>
                                <li>
                                    <a className='dropdown-item' href='/'>
                                        Something else here
                                    </a>
                                </li>
                            </ul>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    </div>
  )
}

export default Navigation
