import React from 'react'


// Layout
import Layout from "../components/Layout/Layout.js"
// import IntroCard from '../components/Cards/IntroCard.js'
// import "./BiographyPage.css"

function BioPage() {
  return (
    <Layout>
      <div style={{marginTop: 200}}></div>
        <section className="about-timeline">
  <div className="wrapper inner-wrapper-padding ">
    <div className="start-point">
      <div className="black-dot" />
      <h4>Start</h4>
      <div className="corner bl" />
    </div>
    <div className="timeline-main">
      <div className="timeline-row">
        <div className="timeline-box timeline-content-img logo-timeline-arrow">
          <div className="timeline-box-wrap">
            <h6>April 11 1999</h6>
            <div className="timeline-content">
              <img src="Images/baby josh.jpg" alt />
              <div className="timeline-content-txt">
                <p>Little Joshie was born! I was a chonky baby too, about 23 inches and 13 pounds if I remember right.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="timeline-box timeline-content-img logo-timeline-arrow">
          <div className="timeline-box-wrap">
            <h6>April 13<br /> 2003</h6>
            <div className="timeline-content">
              <div className="timeline-content-txt">
                <p>Here's a picture of me with <br />my super cool X-Men cake<br /> for my third birthday.</p>
              </div>
              <img src="Images/Third Birthday.JPG" alt width="200px" />
            </div>
          </div>    
        </div>
        <div className="horizontal-line" />
        <div className="verticle-line" />
        <div className="corner top" />
        <div className="corner bottom" />    	
      </div>
      <div className="timeline-row">
        <div className="timeline-box img-right timeline-content-img">
          <div className="timeline-box-wrap">
            <h6>Oct 1<br /> 2003</h6>
            <div className="timeline-content">
              <div className="timeline-content-txt">
                <p style={{textAlign: 'left'}}>This was when I first learned how to skate. I didn't play hockey for another 10 years after this, but it will come in handy later. Also check out how stylish my bomber jacket was.</p>
              </div>
              <img src="Images/Learning to Skate.jpg" alt width="250px" />
            </div>
          </div>
        </div>
        <div className="timeline-box img-right timeline-content-img">
          <div className="timeline-box-wrap">
            <h6>April 4<br />2004</h6>
            <div className="timeline-content">
              <img src="Images/joshbday 042.jpg" alt width="250px" />
              <div className="timeline-content-txt">
                <p style={{textAlign: 'left'}}>Here's another birthday party, this time for my fifth birthday. As you can see I've upgraded my cake to Star Wars which is vastly superior to X-Men.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="timeline-box img-right timeline-content-img">
          <div className="timeline-box-wrap">
            <h6 />
            <div className="timeline-content">
              <div className="timeline-content-txt">
                {/* <p></p> */}
              </div>
              {/* <img src="Images/First Day Second Grade.JPG" alt="" width="200px"> */}
            </div>
          </div>
        </div>
        <div className="horizontal-line" />
        <div className="verticle-line" />
        <div className="corner top" />
        <div className="corner bottom" /> 
      </div>
      <div className="timeline-row">
        <div className="timeline-box img-right timeline-content-img">
          <div className="timeline-box-wrap">
            <h6>2006</h6>
            <div className="timeline-content">
              <div className="timeline-content-txt">
                <p style={{textAlign: 'left'}}>Here's a picture of me and my amazing sister <br /> (Maddie) on my first day of second grade :)</p>
              </div>
            </div>
            <img src="Images/First Day Second Grade.JPG" alt width="300px" />
          </div>
        </div>
        <div className="timeline-box ">
          <div className="timeline-box-wrap">
            <h6>2007</h6>
            <div className="timeline-content">
              <div className="timeline-content-txt">
                <p style={{textAlign: 'left'}}>Around this time I was playing football for<br /> the Arlington Heights' Cowboys.<br /> I played offensive and devensive tackle. <br />I would stop playing football around Grade<br /> 5 when I took up hockey.</p>
              </div>
              {/* <img src="Images/baby josh.jpg" alt=""> */}
            </div>
          </div>
        </div>
        <div className="timeline-box img-right timeline-content-img">
          <div className="timeline-box-wrap">
            <h6>2008</h6>
            <br />
            <img src="Images/First Day Fourth Grade.JPG" alt width="400px" /> 
            <div className="timeline-content">
              <div className="timeline-content-txt">
                <p style={{textAlign: 'left'}}>I couldn't find my third grade picture so we're skipping straight to fourth grade. You can see the football sign in the ground was for the Cowboys football team I was on.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="horizontal-line" />
        <div className="verticle-line" />
        <div className="corner top" />
        <div className="corner bottom" />
      </div>
      <div className="timeline-row">
        <div className="timeline-box img-right timeline-content-img">
          <div className="timeline-box-wrap">
            <h6>2010</h6>
            <div className="timeline-content">
              <div className="timeline-content-txt">
                <p style={{textAlign: 'left'}}>I quit football and relearned how to skate. You'll see here that I'm not in goalie equiptment, but during one of the clinics I was at I played goalie for fun and realized I wanted to be that crazy guy who wanted to get hit with frozen rubber all day.</p>
              </div>
              <img src="Images/Sharks Hockey.JPG" alt width="275px" />
            </div>
          </div>
        </div>
        <div className="timeline-box">
          <div className="timeline-box-wrap">
            <h6>2010-2011</h6>
            <div className="timeline-content">
              <div className="timeline-content-txt">
                <p style={{textAlign: 'left'}}>I switched to goalie and played a season of house league for the Icedogs. I was chosen to be on the "Selects" team which were the "better" players from the house league and got to play some travel games and go to tournaments. </p>
              </div>
              <img src="Images/Icedogs Hockey 2010 2011.jpg" alt />
            </div>
          </div>
        </div>
        <div className="timeline-box ">
          <div className="timeline-box-wrap">
            <h6 />
            <div className="timeline-content">
              <div className="timeline-content-txt">
                <p style={{textAlign: 'left'}} />
              </div>
              {/* <img src="Images/baby josh.jpg" alt=""> */}
            </div>
          </div>
        </div>
        <div className="horizontal-line" />
        <div className="verticle-line" />
        <div className="corner top" />
        <div className="corner bottom" />
      </div>
      <div className="timeline-row">
        <div className="timeline-box timeline-content-img">
          <div className="timeline-box-wrap">
            <h6>March 9 2013</h6>
            <div className="timeline-content">
              <div className="timeline-content-txt">
                <p style={{textAlign: 'left'}}>I switched from Icedogs to the Highland park Falcons. This time I decided to try out for their travel team and was able to make the Central-State team first try! Central-State's is essentially the highest level of hockey before AAA. In this pic you can see me with my coach when we won the State Championship.</p>
              </div>
              <img src="Images/State Champion With Date.JPG" alt />
            </div>
          </div>
        </div>
        <div className="timeline-box ">
          <div className="timeline-box-wrap">
            <h6 />
            <div className="timeline-content">
              <div className="timeline-content-txt">
                <p style={{textAlign: 'left'}} />
              </div>
              {/* <img src="Images/baby josh.jpg" alt=""> */}
            </div>
          </div>
        </div>
        <div className="timeline-box img-right timeline-content-img">
          <div className="timeline-box-wrap">
            <h6>March 9 2013</h6>
            <div className="timeline-content">
              <div className="timeline-content-txt">
                <p style={{textAlign: 'left'}}>Here's another picture of me and my dad immediately after that State Championship game</p>
              </div>
              <img src="Images/State Champion 2012 2013.JPG" alt width="350px" />
            </div>
          </div>
        </div>
        <div className="horizontal-line" />
        <div className="verticle-line" />
        <div className="corner top" />
        <div className="corner bottom" />
      </div>
      <div className="timeline-row">
        <div className="timeline-box  img-right timeline-content-img">
          <div className="timeline-box-wrap">
            <h6>2013</h6>
            <div className="timeline-content">
              <div className="timeline-content-txt">
                <p style={{textAlign: 'left'}}>First day of High School! This is where I'll really start to develop my passion for STEM classes like math and physics, and also take my first computer science class.</p>
              </div>
              <img src="Images/First Day of High School.JPG" alt width="250px" />
            </div>
          </div>
        </div>
        <div className="timeline-box timeline-content-img">
          <div className="timeline-box-wrap">
            <h6>2017</h6>
            <div className="timeline-content">
              <div className="timeline-content-txt">
                <p style={{textAlign: 'left'}}>High School graduation! I had accepted my offer to The University of Illinois Urbana-Champaign! I would be studying Aerospace Engineering with a minor in Computer Science.</p>
              </div>
              <img src="Images/High School Graduation.JPG" alt width="450px" />
            </div>
          </div>
        </div>
        <div className="horizontal-line" />
        <div className="verticle-line" />
        <div className="corner top" />
        <div className="corner bottom" />
      </div>
      <div className="timeline-row">
        <div className="timeline-box img-right timeline-content-img">
          <div className="timeline-box-wrap">
            <h6>2017</h6>
            <div className="timeline-content">
              <div className="timeline-content-txt">
                <p style={{textAlign: 'left'}}>After a relaxing summer I move in to my shoebox of a dorm at UIUC. I lived in FAR (Florida Avenue Residence Halls) which were certainly far from campus. Thankfully I had a bike I would ride to class every day. </p>
              </div>
              <img src="Images/College Move In.JPG" alt width="350px" />
            </div>
          </div>
        </div>
        <div className="timeline-box img-left timeline-content-img">
          <div className="timeline-box-wrap">
            <h6>2021</h6>
            <div className="timeline-content">
              <div className="timeline-content-txt">
                <p style={{textAlign: 'left'}}>I graduate from UIUC! I was so excited to start my Master's at UC Davis and finally have the chance to pursue what I now realize is my dream of working in software development.</p>
              </div>
              <img src="Images/College Graduation Party.jpg" alt width="350px" />
            </div>
          </div>
        </div>
        <div className="horizontal-line" />
        <div className="verticle-line" />
        <div className="corner top" />
        <div className="corner bottom" />
      </div>
      <div className="timeline-row">
        <div className="timeline-box img-right timeline-content-img">
          <div className="timeline-box-wrap">
            <h6>2021</h6>
            <div className="timeline-content">
              <div className="timeline-content-txt">
                <p style={{textAlign: 'left'}}>On my way to UC Davis my girlfriend and I stopped at Pike's Peak, the Salt Flats, and other fantastic places. It's a trip I'll never forget and I would love to do again. (Other than the hotel in Utah not being open to give us a room that wasn't fun at all... I was so tired)</p>
              </div>
              <img src="Images/Trip_to_California.jpg" alt width="350px" style={{transform: 'rotate(180deg)'}} />
            </div>
          </div>
        </div>
        <div className="timeline-box">
          <div className="timeline-box-wrap">
            <h6>2023</h6>
            <div className="timeline-content">
              <div className="timeline-content-txt">
                <p style={{textAlign: 'left'}}>I finally graduated from UC Davis with my Master's in Computer Science. I am spending my days grinding out job apps and working on this website to show what I know and that I can be a valuable asset to a company.</p>
              </div>
              {/* <img src="Images/Trip_to_California.jpg" alt="" width="350px" style="transform: rotate(180deg);"> */}
            </div>
          </div>
        </div>
        <div className="horizontal-line" />
        <div className="verticle-line" />
        <div className="corner top" />
        <div className="corner bottom" />
        <div className="start-point end-point">
          <h4>The Future</h4>
        </div>
      </div>
    </div>
  </div>
</section>

    </Layout>
  )
}

export default BioPage
