import React from 'react'
import './InitialScreen/Stars.css'
import './InitialScreen.css'

function InitialScreen() {
  return (
    <div className="InitialScreen">
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      
    </div>
  )
}

export default InitialScreen
