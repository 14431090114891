import React from "react";
import "./Layout.css";

import Navigation from "./Navigation";

import ContactButtons from "../ContactButtons/ContactButtons";

// Pass the child props
export default function Layout({ children }) {
  return (
    <div>
      <div className="navDiv">
      <Navigation/>
      </div>
      {/* display the child prop */}
      {children}
      <ContactButtons/>
    </div>
  );
}