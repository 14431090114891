import React from 'react'
// import { Counter } from '../features/counter/Counter';
import './MainPage.css';
import BackgroundParticles from '../BackgroundParticles';
import { motion } from "framer-motion"

import { Grid } from '@mui/material';

// Cards 
import IntroCard from "../components/Cards/IntroCard.js"
import EducationCard from '../components/Cards/EducationCard';
import GoalsCard from '../components/Cards/GoalsCard';
import FreeTimeCard from '../components/Cards/FreeTimeCard';
// import AboutAuggieCard from '../components/Cards/AboutAuggieCard';

// Layout
import Layout from "../components/Layout/Layout.js"
// import CardTo10xResearch from '../components/Cards/CardTo10xResearch';
// import InterestsCard from '../components/Cards/InterestsCard';

//
import TestComp from '../TestComp';
// import SolarApplication from '../SolarApplication';

import ProjectSection from '../components/Projects/ProjectSection';
import ResearchSection from '../components/Research/ResearchSection';
// import SkillsSection from '../components/Skills/SkillsSection';


function MainPage() {
  
  return (
    <motion.div
    animate={{opacity: 1}}
    initial={{opacity: 0}}
    transition={{ duration: 2}}
    >

    <Layout>
      {/* <div className='background'> */}
        <BackgroundParticles />
      {/* </div> */}

        <div className='content'>
          <div className='introCard'>
            <motion.div
            initial={{y:-1000}}
            animate={{y:0}}
            transition={{delay: 1.5, duration: 0.5, type:'spring', bounce: 0.3 }}>
              <IntroCard />
            </motion.div>
          </div>
          <Grid container spacing={16} className='threeCardGrid'>
            <Grid item>
              <motion.div
              animate={{x: 0}}
              initial={{x: -1000}}
              transition={{ delay: 1.5, duration: 0.5, type:'spring', bounce: 0.4 }}>
                <EducationCard/>
              </motion.div>
            </Grid>
            <Grid item>
              <motion.div
              animate={{x: 0}}
              initial={{x: -2000}}
              transition={{ delay: 1.7, duration: 1, type:'spring', bounce: 0.4 }}>
                <GoalsCard/>
              </motion.div>
            </Grid>
            <Grid item>
              <motion.div
              animate={{x: 0}}
              initial={{x: -3000}}
              transition={{ delay: 1.9, duration: 1.5, type:'spring', bounce: 0.3 }}>
                <FreeTimeCard/>
              </motion.div>
            </Grid>
          </Grid>

          {/* <div style={{marginTop:'500px'}}></div>
              <InterestsCard/> */}
          <div style={{marginTop:'250px'}}></div>

          {/* <SolarApplication/> */}

          {/* <SkillsSection/> */}

          <ProjectSection/>

          <ResearchSection/>

        </div>

          
      
      
        
        <TestComp/>
          
        
        {/* <canvas id="canvas"></canvas> */}
        
        



    </Layout>
    </motion.div>
  )
}

export default MainPage
