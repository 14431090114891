import React from "react";
import "./ProjectSection.css";
import Container from "react-bootstrap/Container";
// import SectionHeader from "../SectionHeader";
import ProjectCard from "./ProjectCard";
import Row from "react-bootstrap/Row";
import { projects } from "../data.js";
import { Grid } from '@mui/material';

function ProjectSection() {
  return (
    <section id="projects">
      <h1 className="proj-header">Projects</h1>
      <Grid container spacing={5} 
      // direction="column"
      alignItems="center"
      justifyContent="center">
        {projects.map((proj, index) => (
          <ProjectCard proj={proj} />
        ))}
      </Grid>
    </section>
  );
}

export default ProjectSection;
