import {
    AuggieImg,
    WebsiteImg,
    NetflixImg,
    PongImg,
    SVMImg,
    SnakeImg,
    WeatherImg,
    AerochainImg,
    TenImg,
    SecImg,

} from "../Images";

export const projects = [{
        title: "This Website!",
        description: [
            "Responsive personal portfolio",
            "Smooth animations to encourage interaction",
            "Dark mode button and lots of fun features",
        ],
        languages: ["ReactJS", "Bootstrap", "HTML", "CSS", "JavaScript"],
        link: "https://joshuapike.com/",
        img: WebsiteImg,
        featured: false,
        longDesc: [

        ],
    },
    {
        title: "Pong with Deep Q Network",
        description: [
            "Utilized Q-learner to play Pong",
            "Added neural net to boost performance",
            "Replay buffer to break harmful correlations"
        ],
        languages: ["Python", "PyTorch", "Numpy"],
        link: "https://github.com/JoshuaPike/Deep-Q-Network-Pong",
        img: PongImg,
        featured: false,
        longDesc: [
            "Using PyTorch I applied Q-learning and a neural network to Pong. This model was able to easily beat any user it played against. I used the average reward and loss values to tune parameters using an iterative process. Additionally, I implemented a replay buffer to help break the correlation between consecutive samples and found that reducing this buffer size led to faster convergence."
        ],
    },
    {
        title: "Netflix Movie Recommender",
        description: [
            "K-means clustering and deep encoded clustering",
            "Trained on 17,770 movies & ~300,000 ratings",
            "Predict movie ratings for (user, movie) pair"
        ],
        languages: ["Python", "SciPy", "Scikit-Learn", "Pandas", "Numpy"],
        link: "https://github.com/JoshuaPike/Netflix-Movie-Recommender-Deep-Clustering",
        img: NetflixImg,
        featured: false,
        longDesc: [
            "The training set used on the deep clustering model had around 17,770 movie titles and approximately 300,000 movie ratings (1-5) from various users. Using sklearn I applied k-means clustering (with 15 clusters) and deep encoded clustering (also with 15 clusters). These models would take in a (user, movie) pair and give a predicted rating for the movie based on the clustering. With this we could iterate through various movies and determine which movie has the highest predicted rating for a user and recommend that to the user. Additionally, I found that the deep clustering model was slightly better through analyzing the mean squared error."
        ],
    },
    {
        title: "Detecting Digits with an SVM",
        description: [
            "Predicted digits from 0-9",
            "80/20 train/test split",
            "Applied hypothesis and instance transfer"
        ],
        languages: ["Python", "Scikit-Learn", "Numpy", "Pandas"],
        link: "https://github.com/JoshuaPike/State-Vector-Machine",
        img: SVMImg,
        featured: false,
        longDesc: [
            "This project simply applies a state vector machine to predict the digit presented in an image. These digits range from 0 to 9. I used a 80/20 train/test split on the training data. I found that theoretically the model should have a minimum % correct of 67.366% and through cross-validation the model I implemented had a cross-validation of 73.341%. I then applied no transfer, hypothesis transfer, and instance transfer to the target problem of determining if a digit is 1 or 7. For no transfer I simply trained on the target problem aquiring a 79.045% correct. For hypothesis transfer I first trained our model on the problem of determining 1 vs. 9, and then transferred it to the target problem: this gave us a % correct of 80.371%. For instance transfer, I trained on and obtained the support vectors for the source problem (1 vs. 9) and transferred the support vectors to the target problem by modulating the objective and constraints with the support vectors to train. This gave us a % correct of 81.167%."
        ],
    },
    {
        title: "Snake on RISC-V 32b OS",
        description: [
            "Developed our own OS for game design",
            "Implemented and documented extensive API",
            "Developed Snake on our own OS"
        ],
        languages: ["C", "RISC-V", "C++"],
        link: "https://github.com/JoshuaPike/OS-Snake",
        img: SnakeImg,
        featured: false,
        longDesc: [
            "In this project we designed the game Snake on a RISC-V 32b architecture. We also had to provide a sufficient API (and documentation) so that other teams could develop their own games on our OS."
        ],
    },
    {
        title: "Android Weather App",
        description: [
            "Get weather at input location",
            "GET and POST requests to Weather API",
            "Parsed Json to display information"
        ],
        languages: ["Java", "MetaWeather API", "Android Studio"],
        link: "https://github.com/JoshuaPike/Pike-Bruchhauser-cs125-Final-Project",
        img: WeatherImg,
        featured: false,
        longDesc: [
            "This project was done early in my undergrad. I designed a weather app using a weather API on Android. You could input your location, or any location, and it would display the current and forcasted weather."
        ],
    },
    
];

export const research = [{
        title: "Studying 10x Engineers Through Code Analysis",
        description: [
            "Determined if 10x engineers exist",
            "Mined version control history of 5 repos",
            "Analyzed contributor productivity to determine 10x"
        ],
        languages: ["Python", "PyDriller", "Lizard", "Numpy", "Pandas"],
        link: "https://drive.google.com/file/d/1gBbzbO_b_iuri-z7hkS5TF9p4kr3dUEY/view",
        img: TenImg,
        featured: false,
        longDesc: [
            "In this research we attempt to demystify the idea of a 10x engineer. To do this we scraped 5 GitHub repositories for years of commit data, and analyzed this data. We utilize a productivity metric (churn over time between commits) for each contributor and compare that to the average productivity for the entire repo. If a contributor had 10 times the average contributor's productivity they would be labeled as a 10x engineer. We also examined the correlation between this labeling and contributor code complexity, code descriptiveness, and code ownership."
        ],
    },
    {
        title: "Effects of Importance & Availability on Wait Time",
        description: [
            "Examined correlation between importance and availability on a user's wait time",
            "Three webpages of varying importance",
            "Higher the importance longer a user waited"
        ],
        languages: ["Python", "JS", "Py4web", "Vue.js"],
        link: "https://drive.google.com/file/d/1MAOfiSjS-OO5qn0B75PZpkDeHX1VHlBt/view",
        img: SecImg,
        featured: false,
        longDesc: [
            "Here we sought to examine the correlation between the importance and availability of a website on a user's wait time. We constructed three webpages of varying importance and had students at UC Davis visit them and record how long they were willing to wait for the page to load. We implemented fake wait times that would be randomly selected. We found that the more important the website the longer a user was willing to wait. Additionally, we implemented multiple loading page styles that offered varying levels of information to determine which caused users to wait longer for a page to load."
        ],
    },
    {
        title: "Aerochain: A Distributed No Fly List",
        description: [
            "Distributed no-fly-list on the blockchain",
            "Airlines registered as admins",
            "Add people to list, modeled as NFTs"
        ],
        languages: ["Py4web", "Radix", "Scrypto", "Vue.js"],
        link: "https://drive.google.com/file/d/1V4mFyYFKXX2ZYPhxcQjd4T1KUCQRAIp5/view",
        img: AerochainImg,
        featured: false,
        longDesc: [
            "Using Radix's Scrypto, we created a distributed No-Fly-List on the blockchain. Seeing as each airline has their own no-fly-list we were determined to implement a way for these airlines to share a no-fly-list that was both secure and accurate. With Scrypto we would register Airlines as admins on this service. These admins then could add people to the no-fly-list through a webpage. These people would be modeled as NFTs and be accessible through Aerochain. Anyone could do a lookup of certian people on the blockchain to see if they were on the no-fly-list."
        ],
    }
];

export var nightMode = false;

export const toggleNightMode = () => {
    
    nightMode = !nightMode;
    updateNightMode();
};

export const updateNightMode = () =>{
    let root = document.documentElement;
    if (nightMode) {
        root.style.setProperty("--main-bg-color", "#080705");
        root.style.setProperty("--main-text-color", "#abdbe3");
        root.style.setProperty("--secondary-bg-color", "#111111");
        root.style.setProperty("--accent-color-1", "#abdbe3");
    } else {
        root.style.setProperty("--main-bg-color", "#abdbe3");
        root.style.setProperty("--main-text-color", "#000000");
        root.style.setProperty("--secondary-bg-color", "#FFFFFF");
        root.style.setProperty("--accent-color-1", "#000000");
    }
}

export const topSkills = ["Python", "ElasticSearch", "ReactJS", "Pandas", "Flask"]

export const skills = [{
        title: "Languages",
        items: [
            "Python",
            "C++",
            "Java",
            "Javascript",
            "HTML",
            "CSS",
            "SQL",
            "Processing",
            "C#",
            "p5.js",
            "NodeJS",
        ],
    },
    {
        title: "Frameworks",
        items: [
            "ReactJS",
            "Bootstrap",
            "jQuery",
            "p5.js",
            "GTest",
            "Beautiful Soup",
            "Express.js",
            "SemanticUI",
            "Express.js",
            "Selenium",
        ],
    },
    {
        title: "Tools",
        items: [
            "Git/Github",
            "NPM",
            "Postman",
            "AWS",
            "GCP",
            "Numpy",
            "Heroku",
            "CLIs",
            "MongoDB",
            "VirtualBox",
            "ffmpeg",
            "NLTK",
        ],
    },
    {
        title: "Other",
        items: [
            "Linux/Unix",
            "Figma",
            "Arduino",
            "Photoshop",
            "Premiere Pro",
            "Illustrator",
            "After Effects",
            "3D Printing",
            "Agile",
            "CAD",
            "MS Office",
            "G Suite",
            "Audacity",
        ],
    },
];
